import { useFetch, useNuxtApp, useRuntimeConfig } from "#app";
export function useCsrfFetch(request, arg1, arg2) {
  const [opts = {}, autoKey] = typeof arg1 === "string" ? [{}, arg1] : [arg1, arg2];
  const { csrf, headerName } = useCsrf();
  opts.headers = opts.headers || {};
  opts.headers[headerName] = csrf;
  return useFetch(
    request,
    opts,
    // @ts-expect-error we pass an extra argument with the resolved auto-key to prevent another from being injected
    autoKey
  );
}
export function useLazyCsrfFetch(request, arg1, arg2) {
  const [opts, autoKey] = typeof arg1 === "string" ? [{}, arg1] : [arg1, arg2];
  return useCsrfFetch(
    request,
    {
      ...opts,
      lazy: true
    },
    // @ts-expect-error we pass an extra argument with the resolved auto-key to prevent another from being injected
    autoKey
  );
}
export function useCsrf() {
  const headerName = useRuntimeConfig().public.csurf.headerName ?? "";
  if (import.meta.server) {
    return { csrf: useNuxtApp().ssrContext?.event?.context?.csrfToken, headerName };
  }
  const metaTag = window.document.querySelector('meta[name="csrf-token"]');
  return { csrf: metaTag?.getAttribute("content"), headerName };
}
