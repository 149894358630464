import { createVuetify } from 'vuetify'

import type { ThemeDefinition } from 'vuetify'

import '@mdi/font/css/materialdesignicons.min.css'
import '@/assets/scss/style.scss'

const dreissigelfTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#14213D',
    secondary: '#e5e5e5',
    accent: '#FCA311',
    'on-primary': '#000000'
  }
}

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: 'dreissigelf',
      themes: { dreissigelf: dreissigelfTheme }
    },
    defaults: {
      VBtn: {
        style: 'text-transform: none; letter-spacing:0; border-radius: 10px;'
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
