<script setup lang="ts">
definePageMeta({
  layout: 'blank'
})

defineComponent({
  name: 'ErrorPage'
})
</script>

<template>
  <div class="d-flex justify-center align-center text-center h-100">
    <div class="px-5">
      <v-img src="/images/svg/error.svg" max-width="500" alt="404" />
      <h1 class="text-h1 pt-3">Oh, nein!!!</h1>
      <h4 class="text-h4 my-8">Das, was Sie suchen, kann ich nicht finden...</h4>
      <v-btn flat color="primary" class="mb-4" to="/">Zur Startseite</v-btn>
    </div>
  </div>
</template>
