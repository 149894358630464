import { $fetch } from "ofetch";
import { defineNuxtPlugin, useCsrf } from "#imports";
export default defineNuxtPlugin(() => {
  const { csrf, headerName } = useCsrf();
  const csrfFetch = (request, options, fetch = $fetch) => {
    if (!options)
      options = {};
    options.headers = options.headers || {};
    options.headers[headerName] = csrf;
    return fetch(request, options);
  };
  return {
    provide: { csrfFetch }
  };
});
