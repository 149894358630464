import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin(nuxtApp => {
  const {
    public: { matomo }
  } = useRuntimeConfig()

  nuxtApp.vueApp.use(VueMatomo, {
    host: matomo.host,
    siteId: matomo.siteId,
    cookieDomain: '*.dreissigelf.de',
    domains: ['*.dreissigelf.de'],
    router: nuxtApp.$router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    requireCookieConsent: false
  })
})
